import { emptyFalseyStrings, removeUndefinedFromObj } from "@packages/common/object";
import { escapeNewLine, removeAllWhitespace } from "@packages/common/string";
import { OMIT_YEAR } from "@shared/models/constants";
import { applyPatch, compare, getValueByPointer, JsonPatchError, Operation } from "fast-json-patch";
import { unraw } from "unraw";
import VCard, { Property } from "vcf";
import { Contact } from "../models/Contact";
import {
  ArbitraryDate,
  Email,
  ImHandle,
  IsDefault,
  IsForced,
  PhoneNumber,
  Photo,
  PhysicalAddress,
  Relative,
  WebPage,
} from "../models/types";
import {
  TitledockVCard,
  TitledockVCardAdr,
  TitledockVCardDate,
  TitledockVCardImpp,
  TitledockVCardSocial,
  TitledockVCardTelEmailUrl,
} from "../types";

type PropertyWithGroup = Property & { group?: string; type?: string | string[]; value?: string };
type ImppPropertyWithGroup = PropertyWithGroup & { xServiceType: string };
type SocialPropertyWithGroup = PropertyWithGroup & { xUser?: string };
type PhotoProperty = Property & { xAbcropRectangle?: string; value: string; encoding?: string };

type AppleBsMapping = {
  [i: string]: {
    [j: string]: Property;
  };
};
const labelRegex = /_\$!<(.+)>!\$_/;
function getAppleVCardLabel(label: string) {
  const [, matched] = String(label).match(labelRegex) || [];
  return matched || label;
}

function getGenericPrefType(types: string[]) {
  let type;
  let isDefault;
  const typeMapping: { [key: string]: boolean } = {};
  for (const i of types) {
    typeMapping[i?.toLowerCase()] = true;
  }
  const isHome = typeMapping["home"];
  const isWork = typeMapping["work"];
  const isOther = typeMapping["other"];
  type = isHome ? "home" : isWork ? "work" : isOther ? "other" : "";
  if (typeMapping["pref"]) isDefault = true;

  return { type, isDefault };
}

function getAdr(adr: PropertyWithGroup): TitledockVCardAdr {
  return {
    type: String(Array.isArray(adr.type) ? adr?.type[0] : adr.type) || "",
    value: String(adr.valueOf()),
  };
}

function getTelType(prop: PropertyWithGroup, appleBsMapping: AppleBsMapping): PhoneNumber["type"] {
  const type =
    prop.group && appleBsMapping[prop.group]
      ? appleBsMapping[prop.group]["xAblabel"]?.valueOf()
      : prop.type;

  if (type && !Array.isArray(type) && prop.group) {
    const matches = type.match(labelRegex);
    if (matches) {
      const [, matched] = matches;
      return matched ? matched.toLowerCase() : "";
    }
    return type;
  }

  let isDefault: IsDefault = IsDefault.NO;

  const typeMapping: { [key: string]: boolean } = {};
  if (Array.isArray(type)) {
    for (const i of type) {
      const t = String(i).toLowerCase();
      if (t === "pref") isDefault = IsDefault.YES;
      typeMapping[t] = true;
    }
  } else {
    typeMapping[String(type).toLowerCase()] = true;
  }

  const isHome = typeMapping["home"];
  const isWork = typeMapping["work"] || typeMapping["business"];
  const isFax = typeMapping["fax"];
  const isPager = typeMapping["pager"];
  const isMobile = typeMapping["cell"] || typeMapping["iphone"];
  const isPhone = typeMapping["voice"] || isMobile;
  const isMain = typeMapping["main"];

  if (isWork && isFax) return "businessFax";
  if (isHome && isFax) return "homeFax";
  if (isWork) return "business";
  if (isHome) return "home";
  if (isPager) return "pager";
  if (isMobile) return "mobile";
  if (isPhone) return "voice";
  if (isMain) return "main";

  return "other";
}

function getTel(prop: PropertyWithGroup, appleBsMapping: AppleBsMapping) {
  return removeUndefinedFromObj({
    value: String(prop.valueOf()),
    type: getTelType(prop, appleBsMapping),
  });
}

function getUrlEmail(
  prop: PropertyWithGroup,
  appleBsMapping: AppleBsMapping
): TitledockVCardTelEmailUrl {
  let type =
    prop.group && appleBsMapping[prop.group]
      ? appleBsMapping[prop.group]["xAblabel"]?.valueOf()
      : !Array.isArray(prop.type)
      ? prop.type
      : "";

  let isDefault = IsDefault.NO;
  if (Array.isArray(prop.type) && !type) {
    const genericType = getGenericPrefType(prop.type);
    type = genericType.type;
    isDefault = genericType.isDefault ? IsDefault.YES : IsDefault.NO;
  } else if (type && prop.group) {
    const matches = type.match(labelRegex);
    if (matches) {
      const [, matched] = matches;
      type = matched ? matched.toLowerCase() : "";
    }
  }

  return removeUndefinedFromObj({
    value: String(prop.valueOf()),
    type,
    isDefault,
  });
}

function getImpp(impp: ImppPropertyWithGroup, appleBsMapping: AppleBsMapping): TitledockVCardImpp {
  let type: ImHandle["type"] =
    impp.group && appleBsMapping[impp.group]
      ? appleBsMapping[impp.group]["xAblabel"]?.valueOf()
      : !Array.isArray(impp.type)
      ? impp.type
      : "";

  let isDefault = IsDefault.NO;

  if (Array.isArray(impp.type) && !type) {
    const genericType = getGenericPrefType(impp.type);
    type = genericType.type;
    isDefault = genericType.isDefault ? IsDefault.YES : IsDefault.NO;
  }
  return removeUndefinedFromObj<TitledockVCardImpp>({
    value: String(impp.valueOf()),
    type: (Array.isArray(type) ? type[0] : type) || "",
    service: (impp.xServiceType || "").toLowerCase(),
    isDefault,
  });
}

const vCardValRegex = (key: string) => new RegExp(`${key}=(.+);`, "i");
const twitterLinkRegex = /(twitter\.com\/\b)(?!.*\b\1\b)(.*)/i;
const linkedinLinkRegex = /(linkedin\.com\/in\/\b)(?!.*\b\1\b)(.*)/i;
function getSocial(
  social: SocialPropertyWithGroup,
  appleBsMapping: AppleBsMapping
): TitledockVCardSocial {
  const value = String(social.valueOf());

  function getSocialType() {
    return value.includes("twitter.com")
      ? "twitter"
      : value.includes("linkedin.com")
      ? "linkedin"
      : value.includes("facebook.com")
      ? "facebook"
      : value.includes("flickr.com")
      ? "flickr"
      : value.includes("myspace.com")
      ? "myspace"
      : value.includes("weibo.com")
      ? "weibo"
      : String(social.type);
  }

  if (social.group) {
    const appleBsMappingSocialGroup = appleBsMapping[social.group];
    const type = appleBsMappingSocialGroup?.xAblabel?.valueOf();
    return {
      value,
      type: type || getSocialType(),
    };
  }

  const normalizedVal = value.toLowerCase();

  // special cases to handle bad data
  if (normalizedVal.includes("type=")) {
    if (normalizedVal.includes("type=twitter")) {
      const [, xUserId] = normalizedVal.match(vCardValRegex("x-userid")) || [];
      const [, xUser] = normalizedVal.match(vCardValRegex("x-user")) || [];

      const handleUrl = `https://twitter.com/${xUser}`;
      const idUrl = `https://twitter.com/i/user/${xUserId}`;
      return {
        isDefault: IsDefault.NO, // iCloud does not support setting default social handle
        value: xUser ? handleUrl : xUserId ? idUrl : "",
        type: "twitter",
        handle: xUser || idUrl || "",
      };
    }
  }

  if (normalizedVal.includes("twitter")) {
    const [twitterUrl, , twitterUrlHandle] = normalizedVal.match(twitterLinkRegex) || [];
    if (twitterUrl) {
      return {
        isDefault: IsDefault.NO,
        value: `https://${twitterUrl}`,
        type: "twitter",
        handle: twitterUrlHandle || twitterUrl,
      };
    }
  }

  if (normalizedVal.includes("linkedin")) {
    const [linkedinUrl, , linkedInHandle] = normalizedVal.match(linkedinLinkRegex) || [];
    if (linkedinUrl) {
      return {
        isDefault: IsDefault.NO,
        value: `https://${linkedinUrl}`,
        type: "linkedin",
        handle: linkedInHandle,
      };
    }
  }

  return removeUndefinedFromObj({
    isDefault: IsDefault.NO, // iCloud does not support setting default social handle
    value,
    type: getSocialType(),
    handle: social.xUser || "",
  });
}

function getGroupedContact(xAddressbookserverMember: Property) {
  const value = xAddressbookserverMember.valueOf();
  return value.replace("urn:uuid:", "");
}

function getPhotos(photo: PhotoProperty): TitledockVCard["photos"] {
  const type = photo["value"];
  const crop = photo["xAbcropRectangle"];
  const value = photo.valueOf();
  const encoding = photo["encoding"];
  return [
    removeUndefinedFromObj({
      encoding,
      type,
      crop,
      value,
    }),
  ];
}

function getDatesRelatives(
  prop: PropertyWithGroup,
  appleBsMapping: AppleBsMapping
): TitledockVCardDate {
  const value = prop.valueOf();
  const label =
    prop.group && appleBsMapping[prop.group]
      ? appleBsMapping[prop.group]["xAblabel"]?.valueOf()
      : String(prop.type);
  return {
    label: getAppleVCardLabel(label) || "",
    value,
  };
}

export const groupKeys = ["xAblabel", "xAbdate"];

export function parseVCardString(vCardString: string): TitledockVCard | null {
  if (!vCardString) return null;
  const escapedVCardString = unraw(vCardString);
  const card = new VCard().parse(escapedVCardString);
  const { data } = card;
  const cardObj = {} as TitledockVCard;

  const appleBsMapping: AppleBsMapping = {};

  function setAppleBsMapping(prop: PropertyWithGroup, key: string) {
    if (prop.group) {
      appleBsMapping[prop.group] = {
        ...(appleBsMapping[prop.group] || {}),
        [key]: prop,
      };
    }
  }

  for (const key of groupKeys) {
    if (data.hasOwnProperty(key)) {
      if (data[key] instanceof Property) {
        setAppleBsMapping(data[key] as PropertyWithGroup, key);
      } else if (Array.isArray(data[key])) {
        for (const prop of data[key] as Property[]) {
          setAppleBsMapping(prop as PropertyWithGroup, key);
        }
      }
    }
  }

  for (const key in data) {
    switch (key) {
      case "photo":
        cardObj["photos"] = getPhotos(data[key] as PhotoProperty);
        break;
      case "xAddressbookserverKind":
        cardObj["groupedContact"] = !data["xAddressbookserverMember"]
          ? []
          : Array.isArray(data["xAddressbookserverMember"])
          ? (data["xAddressbookserverMember"] as Property[]).map(getGroupedContact)
          : [getGroupedContact(data["xAddressbookserverMember"] as Property)];
        break;
      case "bday":
        cardObj[key] = {
          omitYear: (data[key] as Property & { xAppleOmitYear?: string }).xAppleOmitYear || "",
          type: (data[key] as Property & { value: string }).value || "", // no idea why this field was named "value", but its val is usually "date"
          value: String(data[key].valueOf()),
        };
        break;
      case "xAbdate":
        cardObj["dates"] = Array.isArray(data[key])
          ? (data[key] as PropertyWithGroup[]).map((props) =>
              getDatesRelatives(props, appleBsMapping)
            )
          : [getDatesRelatives(data[key] as PropertyWithGroup, appleBsMapping)];
        break;
      case "xAbrelatednames":
        cardObj["relatives"] = Array.isArray(data[key])
          ? (data[key] as PropertyWithGroup[]).map((props) =>
              getDatesRelatives(props, appleBsMapping)
            )
          : [getDatesRelatives(data[key] as PropertyWithGroup, appleBsMapping)];
        break;
      case "adr":
        // apple does add an xAbadr mapping, but no useful data
        cardObj[key] = Array.isArray(data[key])
          ? (data[key] as PropertyWithGroup[]).map(getAdr)
          : [getAdr(data[key] as PropertyWithGroup)];
        break;
      case "tel":
        cardObj[key] = Array.isArray(data[key])
          ? (data[key] as PropertyWithGroup[]).map((props) => getTel(props, appleBsMapping))
          : [getTel(data[key] as PropertyWithGroup, appleBsMapping)];
        break;
      case "url":
        cardObj[key] = Array.isArray(data[key])
          ? (data[key] as PropertyWithGroup[]).map((props) => getUrlEmail(props, appleBsMapping))
          : [getUrlEmail(data[key] as PropertyWithGroup, appleBsMapping)];
        break;
      case "email":
        cardObj[key] = Array.isArray(data[key])
          ? (data[key] as PropertyWithGroup[]).map((props) => getUrlEmail(props, appleBsMapping))
          : [getUrlEmail(data[key] as PropertyWithGroup, appleBsMapping)];
        break;
      case "impp":
        cardObj[key] = Array.isArray(data[key])
          ? (data[key] as ImppPropertyWithGroup[]).map((props) => getImpp(props, appleBsMapping))
          : [getImpp(data[key] as ImppPropertyWithGroup, appleBsMapping)];
        break;
      case "xSocialprofile":
        cardObj["social"] = Array.isArray(data[key])
          ? (data[key] as SocialPropertyWithGroup[]).map((props) =>
              getSocial(props, appleBsMapping)
            )
          : [getSocial(data[key] as SocialPropertyWithGroup, appleBsMapping)];
        break;
      // these are Apple BS fields, not sure if we want to use them, keeping for now
      case "xPhoneticOrg":
        cardObj["phoneticOrg"] = String(data[key].valueOf());
        break;
      case "xPhoneticFirstName":
        cardObj["phoneticFirstName"] = String(data[key].valueOf());
        break;
      case "xPhoneticLastName":
        cardObj["phoneticLastName"] = String(data[key].valueOf());
        break;
      case "version":
      case "uid":
      case "n":
      case "fn":
      case "org":
      case "nickname":
      case "title":
      case "note":
      case "prodid":
      case "rev":
        cardObj[key] = String(data[key].valueOf());
    }
  }
  return cardObj;
}

/*
  Stringify to vCard
 */

type VCardGroup = {
  xAblabel?: string;
  xAbrelatednames?: string;
  xAbdate?: string;
};

type VCardParams = { group?: string; type?: string | string[] };

function useVCardParams(type: string | string[]) {
  const params: VCardParams = {};
  if (type) {
    if (Array.isArray(type)) {
      // a weird way to have multiple types in the vcard string
      params.type = type.reduce((prev, cur) => prev + ";type=" + cur);
    } else params.type = type;
  }
  return params;
}

export function addVCardAdr(physicalAddresses: PhysicalAddress[] = [], vcard: VCard) {
  for (const address of physicalAddresses) {
    const {
      street,
      line2,
      city,
      state,
      postalCode,
      country,
      type = "",
    } = emptyFalseyStrings(address);
    vcard.add(
      "adr",
      [
        undefined,
        undefined,
        escapeNewLine(`${street}\n${line2}`),
        city,
        state,
        postalCode,
        country,
      ].join(";"),
      { type }
    );
  }
}

export function getAppleBsMarkdown(type: string = "") {
  if (type === "school") return "_$!<School>!$_";
  if (type === "homepage") return "_$!<HomePage>!$_";
  return type;
}

export function addVCardTel(phoneNumbers: PhoneNumber[] = [], vcard: VCard, groups: VCardGroup[]) {
  for (const tel of phoneNumbers) {
    const { value, type } = tel;
    const iCloudType: string | string[] =
      type === "businessFax"
        ? ["work", "fax"]
        : type === "homeFax"
        ? ["home", "fax"]
        : type === "organizationMain"
        ? ["work", "pref"]
        : type === "business"
        ? "work"
        : type === "home"
        ? "home"
        : type === "pager"
        ? "pager"
        : type === "mobile"
        ? "cell"
        : type === "voice"
        ? "voice"
        : type === "main"
        ? "main"
        : "";

    // if we still cannot determine type at this point - it was likely a custom type
    // create a vCard group entry to house the value and label
    const params = useVCardParams(iCloudType);
    if (!iCloudType) {
      groups.push({ xAblabel: getAppleBsMarkdown(type) });
      params.group = `item${groups.length}`;
    }
    vcard.add("tel", value, params);
  }
}

export function addVCardEmail(emails: Email[] = [], vcard: VCard, groups: VCardGroup[]) {
  for (const email of emails) {
    const { value, type, isDefault } = email;
    const iCloudType: string | string[] =
      type === "home"
        ? ["home", "internet"]
        : type === "work"
        ? ["work", "internet"]
        : type === "other"
        ? ["other", "internet"]
        : "";

    const params = useVCardParams(iCloudType);
    if (!iCloudType) {
      groups.push({ xAblabel: getAppleBsMarkdown(type) });
      params.group = `item${groups.length}`;
    }
    if (isDefault === IsDefault.YES) {
      if (params.type && typeof params.type === "string") {
        params.type = [params.type, "pref"];
      }
    }
    vcard.add("email", value, params);
  }
}

export function addVCardImHandles(imHandles: ImHandle[] = [], vcard: VCard, groups: VCardGroup[]) {
  for (const handle of imHandles) {
    const { value, type = "", service = "" } = handle;
    let params = {};
    if (["work", "home", "other"].includes(type)) params = { type, xServiceType: service };
    else {
      groups.push({ xAblabel: type });
      params = { type, group: `item${groups.length}`, xServiceType: service };
    }
    vcard.add("impp", value, params);
  }
}

export function addVCardWebPagesSocial(
  webPages: WebPage[] = [],
  vcard: VCard,
  groups: VCardGroup[]
) {
  for (const webPage of webPages) {
    const { value, label, service, type = "" } = webPage;
    if (type === "social") {
      // social links are mapped differently than pages
      if (!label) {
        // no user handle, must be custom IM with group
        groups.push({ xAblabel: type });
        vcard.add("xSocialprofile", value, { group: `item${groups.length}` });
      } else {
        vcard.add("xSocialprofile", value, {
          xUser: label || "",
          type: Array.isArray(service)
            ? service.reduce((prev, cur) => prev + ";type=" + cur)
            : service,
        });
      }
    } else {
      // web pages
      if (["blog", "work", "home", "other"].includes(type)) {
        vcard.add("url", value, { type });
      } else {
        groups.push({ xAblabel: getAppleBsMarkdown(type) });
        vcard.add("url", value, { group: `item${groups.length}` });
      }
    }
  }
}

export function addDatesRelatives(
  key: "xAbdate" | "xAbrelatednames",
  props: (ArbitraryDate | Relative)[] = [],
  vcard: VCard,
  groups: VCardGroup[]
) {
  for (const prop of props) {
    const { label, value } = prop;
    if (label?.toLowerCase() === "other") {
      vcard.add(key, String(value), { type: label }); // icloud uses "value" field to store label/type
    } else {
      const xAblabel = key === "xAbrelatednames" ? `_$!<${label}>!$_` : label;
      groups.push({ xAblabel });
      vcard.add(key, String(value), { group: `item${groups.length}` });
    }
  }
}

export function addPhotos(photos: Photo[] = [], vcard: VCard) {
  for (const photo of photos) {
    const { value, type, encoding, crop, isDefault } = photo;
    if (isDefault === IsDefault.YES || photos.length === 1) {
      const params = removeUndefinedFromObj({
        encoding,
        xAbcropRectangle: crop,
        value: type, // icloud uses value to store type
      });
      vcard.add("photo", value, params);
    }
  }
}

export function addBday(birthday: Contact["birthday"], vcard: VCard) {
  if (birthday) {
    const [year] = birthday.split("-");
    const omitYear = String(year) === OMIT_YEAR ? OMIT_YEAR : "";
    const params: { [x: string]: string } = { value: "date" };
    if (omitYear) {
      params["xAppleOmitYear"] = year;
    }
    vcard.set("bday", birthday, params);
  }
}

export function addAppleGroups(vcard: VCard, groups: VCardGroup[]) {
  for (const key in groups) {
    const group = groups[key];
    const params = { group: `item${Number(key) + 1}` };
    if (group.xAblabel) vcard.add("xAblabel", group.xAblabel, params);
    if (group.xAbdate) vcard.add("xAbdate", group.xAbdate, params);
    if (group.xAbrelatednames) vcard.add("xAbrelatednames", group.xAbrelatednames, params);
  }
}

export function getVCardFromContact(contact: Contact): string {
  const {
    surname,
    givenName,
    middleName,
    birthday,
    prefix,
    suffix,
    id,
    companyName,
    departmentName,
    jobTitle,
    nickname,
    notes,
    physicalAddresses,
    phoneNumbers,
    emails,
    webPages,
    imHandles,
    dates,
    relatives,
    photos,
  } = contact;
  const groups: VCardGroup[] = [];
  const vcard = new VCard();
  vcard.version = "3.0";
  vcard.set("uid", id);
  vcard.set("n", [surname, givenName, middleName, prefix, suffix].join(";"));
  vcard.set("fn", [prefix, givenName, surname, suffix].join(" "));
  vcard.set("org", [companyName, departmentName].join(";"));
  vcard.set("title", jobTitle || "");
  vcard.set("nickname", nickname || "");
  vcard.set("note", escapeNewLine(notes));
  addPhotos(photos, vcard);
  addBday(birthday, vcard);
  addVCardAdr(physicalAddresses, vcard);
  addVCardTel(phoneNumbers, vcard, groups);
  addVCardEmail(emails, vcard, groups);
  addVCardImHandles(imHandles, vcard, groups);
  addVCardWebPagesSocial(webPages, vcard, groups);
  addDatesRelatives("xAbdate", dates, vcard, groups);
  addDatesRelatives("xAbrelatednames", relatives, vcard, groups);
  addAppleGroups(vcard, groups);
  vcard.set("prodid", "-//Titledock Inc.//Contact//EN");
  vcard.set("rev", new Date().toISOString());

  return vcard.toString("3.0", "utf-8");
}

export function getVCardFromContactGroup({
  name,
  id,
  vendorContactIds,
}: {
  name: string;
  id: string;
  vendorContactIds: string[];
}) {
  const vcard = new VCard();
  vcard.version = "3.0";
  vcard.set("uid", id);
  vcard.set("n", name);
  vcard.set("fn", name);
  vcard.set("xAddressbookserverKind", "group");
  for (const vcId of vendorContactIds) {
    vcard.add("xAddressbookserverMember", `urn:uuid:${vcId}`);
  }
  vcard.set("prodid", "-//Titledock Inc.//Contact Group//EN");
  vcard.set("rev", new Date().toISOString());
  return vcard.toString("3.0", "utf-8");
}

function isUpdateIgnored(path: string) {
  const ignoredPaths = ["updatedAt", "isDefault"];
  return ignoredPaths.some((end) => path.endsWith(end));
}
export function applyUpdatesToEntity<T>(
  entity: T,
  updates: ReturnType<typeof compare>,
  isForced: IsForced = IsForced.NO
) {
  const ops: Operation[] = [];

  for (const i of updates) {
    if (i.op === "test") {
      if (isForced !== IsForced.YES && !isUpdateIgnored(i.path)) {
        console.log({ notIgnored: i.path });
        let entityVal = getValueByPointer(entity, i.path);
        if (typeof entityVal === "string" || typeof i.value === "string") {
          entityVal = removeAllWhitespace(unraw(entityVal || "")).toLowerCase();
          i.value = removeAllWhitespace(unraw(i.value || "")).toLowerCase();
        }

        if (entityVal && i.value && entityVal !== "null" && i.value !== "null") {
          if (entityVal !== i.value) {
            throw new JsonPatchError(
              `Test value - ${JSON.stringify(
                i.value
              )} does not equal to entity value - ${JSON.stringify(entityVal)} at ${i.path}`,
              "TEST_OPERATION_FAILED"
            );
          }
        }
      }
    } else {
      // must be change op
      // add to list to be applied since test did not throw
      ops.push(i);
    }
  }

  return applyPatch(entity, ops);
}
