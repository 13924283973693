import uuid from "@packages/common/uuid";
import { getVCardFromContact } from "@shared/icloud/sync";
import { ContactRow } from "@shared/models/Contact";
import jsonexport from "jsonexport/dist";

const exportableFields = [
  "userId",
  "id",
  "givenName",
  "middleName",
  "surname",
  "suffix",
  "prefix",
  "nickname",
  "birthday",
  "companyName",
  "departmentName",
  "jobTitle",
  "managerName",
  "notes",
  "pictureUrl",
  "emails",
  "relatives",
  "dates",
  "photos",
  "imHandles",
  "physicalAddresses",
  "phoneNumbers",
  "webPages",
];

export function exportContactsToCsvDownload(contacts: ContactRow[]) {
  const rows = [];
  let userId: string = "no-contacts-selected";
  for (const contact of contacts) {
    const row: string | number | { [key: string]: any } = {};
    userId = contact.userId; // set the UserId for naming the download file
    for (const [key, value] of Object.entries(contact)) {
      if (!exportableFields.includes(key)) continue;
      if (Array.isArray(value)) {
        const nestedObj: { [key: string]: any } = {};
        value.forEach(function (val, i) {
          nestedObj[`${i}`] = val;
        });
        row[key] = nestedObj;
      } else {
        row[key] = value;
      }
    }
    rows.push(row);
  }
  const csvStr = getCsvStrFromContacts(rows);
  makeDownload(csvStr, `${userId}_${uuid()}.csv`, "text/csv;encoding:utf-8");
}

export function getCsvStrFromContacts(contacts: any) {
  let csvStr = "";
  jsonexport(contacts, function (err: any, csv: any) {
    if (err) {
      // todo: have to Error handle here for better user experience
      console.log(err);
    }
    csvStr = csv;
  });
  return csvStr;
}

function makeDownload(content: string, fileName: string, mimeType: string) {
  const a = document.createElement("a");
  mimeType = mimeType || "application/octet-stream";

  if (URL && "download" in a) {
    //html5 A[download]
    a.href = URL.createObjectURL(
      new Blob([content], {
        type: mimeType,
      })
    );
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    location.href = "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
  }
}

export function exportContactsToVCardDownload(contacts: ContactRow[]) {
  let vCards = "";
  for (const contact of contacts) {
    vCards += getVCardFromContact(contact) + "\n";
  }
  makeDownload(vCards, `${new Date().toLocaleDateString()}.vcf`, "text/x-vcard");
}
